@import "sanitize.css";

@font-face {
  font-family: "CMU Concrete";
  src: url("/fonts/CMU-Concrete/CMU Concrete.otf");
  font-display: swap;
}

html {
  font-family: "CMU Concrete", monospace;
  font-size: 25px;
}

body {
  margin: 0 auto;
  max-width: 36em;
  padding: 1.2em;
}

button,
option,
select {
  font-family: inherit;
  font-size: inherit;
}

select {
  overflow: clip;
}

blockquote {
  border-left: 2px solid lightgrey;
  color: dimgrey;
  padding-left: 1em;
}
